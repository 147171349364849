/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/Roboto/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('fonts/Roboto/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/Roboto/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/Roboto/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
  url('fonts/Roboto/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/Roboto/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/Roboto/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('fonts/Roboto/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/Roboto/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/Roboto/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
  url('fonts/Roboto/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/Roboto/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
