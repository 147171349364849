$icon-circle-size: 72px;

.feature-box {
  @extend .tile;
  @extend .white-bg;

  // font-size: 14px;
  // line-height: 24px;

  margin: 0;
  padding: map-get($spacers, 4);

  background-color: #FFF;

  .icon-container {
    text-align: center;
    margin-bottom: 15px;


    .icon {
      color: #FFF;
      font-size: 40px;
      line-height: $icon-circle-size;
      width: $icon-circle-size;
      height: $icon-circle-size;
      background-color: theme-color();
      border-radius: 50%;
      display: inline-block;
    }
  }

  .title, .subtitle {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }

  .title {
    text-align: center;
    color: theme-color();
    margin-bottom: 10px;
    // font-weight: 500;
    font-size: $font-size-lg;
    margin-bottom: map-get($spacers, 4);
  }

  .subtitle {
    text-align: center;
    color: #555;
    // font-weight: 500;
    font-size: $font-size-lg;
  }

  .text {
    border-top: $border-width*2 solid $border-color;
    text-align: justify;
    padding-top: map-get($spacers, 4);
    margin: 0;
  }
}
