// Apply Bootstrap's table styling to all tables (including those in the content specified via Markdown)
table {
  @extend .table;
}

p {
  text-align: justify;
}

code {
  font-weight: 800;
  color: theme-color();
}

.icon {
  color: #595959;
}

section {

  h2.section-heading {
    margin-top: 15px;
    margin-bottom: 45px;
    text-align: center;
  }

  padding: 50px 0;

  &.content:nth-child(even) {
    background-color: #f4f4f4 !important;
  }

  &.blue-bg {
    background-color: theme-color() !important;
    color: #fff;

    a {
      color: #fff;
    }

    .icon {
      color: #fff;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

// .headline { 
//     margin-top: 15px;
//     margin-bottom: 45px;
//     text-align: center;
// }

// Override style for any occurrence other than the first
// note: this is a workaround for :nth-child(1 of .headline) not yet being widely supported
//       see https://stackoverflow.com/a/6447072
.headline ~ .headline {
  margin-top: 0;
}