$vorkurs-default-transition-duration: 0.2s;

.vorkurs-default-transition-duration {
  // duration for hover transition
  transition-duration: $vorkurs-default-transition-duration;
  -ms-transition-duration: $vorkurs-default-transition-duration;
  -moz-transition-duration: $vorkurs-default-transition-duration;
  -webkit-transition-duration: $vorkurs-default-transition-duration;
  -o-transition-duration: $vorkurs-default-transition-duration;
}

.tile {
  border: $border-width solid $border-color;
  border-radius: $border-radius;

  margin-bottom: map-get($spacers, 3);

  padding: map-get($spacers, 4);

  h2 {
    font-size: $font-size-base * 1.5;
  }

  h3 {
    font-size: $font-size-base * 1.25;
  }

  h4 {
    font-size: $font-size-base * 1;
  }

  h5 {
    font-size: $font-size-base * 0.75;
  }

  // coloring
  &.blue-bg {
    background-color: theme-color(primary) !important;
    color: #fff;

    .block-link &:hover {
      box-shadow: 0 25px 30px 0 rgba(0, 0, 0, 0.15);
      background-color: #fff !important;

      .icon {
        color: #595959;
      }
    }
  }

  &.white-bg {
    background-color: #fff !important;
    // color: #000;

    .block-link &:hover {
      box-shadow: 0 25px 30px 0 rgba(0, 0, 0, 0.15);
      background-color: theme-color(primary) !important;
      color: #fff;

      .icon {
        color: #fff;
      }
    }
  }
}