@import "bootstrap/scss/buttons";

// Change card header backgroud color if section background color is grey
section.content:nth-child(even) .vorkurs-collapsible {
  .card-header {
    background-color: #fff;

    button .title-and-annotation .annotation {

    }
  }

  .collapse {
    border-bottom: $border-width solid $border-color;
  }
}

section.content:nth-child(odd) .vorkurs-collapsible {
  .card-header {

    button .title-and-annotation .annotation {
      @extend .badge-secondary;
    }
  }
}


.vorkurs-collapsible {

  h1, .h1 {
    @include font-size($font-size-base * 1.625);
  }

  h2, .h2 {
    @include font-size($font-size-base * 1.500);
  }

  h3, .h3 {
    @include font-size($font-size-base * 1.375);
  }

  h4, .h4 {
    @include font-size($font-size-base * 1.250);
  }

  h5, .h5 {
    @include font-size($font-size-base * 1.125);
  }

  h6, .h6 {
    @include font-size($font-size-base * 1.000);
  }


  .card-header {
    button {
      @extend .btn;
      @extend .btn-link;
      @extend .btn-block;

      // Disable btn-link's text underlining
      &:hover, &:active, &:focus, &:visited {
        text-decoration: none;
      }

      text-align: left;
      align-items: center;

      display: flex;

      .icon {
        margin-right: map-get($spacers, 2);

        &:before {
          @extend .fa;

        }
      }

      &[data-toggle="collapse"] .icon:before {
        content: "\f139";
      }

      &[data-toggle="collapse"].collapsed .icon:before {
        content: "\f13a";
      }

      .title-and-annotation {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;

        .title {
          margin-bottom: 0;
          flex-grow: 1;
          align-self: baseline;

          @include media-breakpoint-down(md) {
            flex-basis: 100%
          }
        }

        .annotation {
          @extend .badge;
          @extend .badge-secondary;

          align-self: center;
        }
      }
    }
  }
}