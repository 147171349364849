$brand-md-max-height: 60px;
$brand-lg-max-height: 75px;
$brand-xl-max-height: 90px;

body {
  // Adapt the content's distance to the pager header height
  // Note: The navigation is floating at the top and is changing its height depending on the screen width. In order for
  //       the content to be always located directly beneath the navigation, it is necessary to adjust the space
  //       between the start of the page and the content.
  @include media-breakpoint-between(xs, md) {
    margin-top: $brand-md-max-height + 10px;
  }

  @include media-breakpoint-only(lg) {
    margin-top: $brand-lg-max-height + 10px;
  }

  @include media-breakpoint-up(xl) {
    margin-top: $brand-xl-max-height + 10px;
  }
}

// =====================================================================================================================
// PAGE HEADER
// =====================================================================================================================
header {
  // navigation bar
  nav {
    @extend .navbar;
    @extend .fixed-top;
    @extend .navbar-expand-lg;
    @extend .navbar-light;

    padding: 0 $spacer !important;

    border-bottom: $border-width solid $border-color !important;
    background-color: #fff;

    // duration for hover transition
    $transition-duration: 0.5s;
    transition-duration: $transition-duration;
    -ms-transition-duration: $transition-duration;
    -moz-transition-duration: $transition-duration;
    -webkit-transition-duration: $transition-duration;
    -o-transition-duration: $transition-duration;

    // Logo
    a.vorkurs-logo {
      @extend .navbar-brand;

      // Remove right margin introduced by bootstrap's navbar-brand
      margin: 0;

      @include media-breakpoint-down(md) {
        // Position the logo in the top right (flex order = before the navigation)
        order: 2;
      }

      @include media-breakpoint-up(lg) {
        // Position the logo after the navigation
        order: 3;
      }

      img {
        @extend .img-fluid;

        @include media-breakpoint-down(md) {
          max-height: $brand-md-max-height;
        }

        @include media-breakpoint-only(lg) {
          max-height: $brand-lg-max-height;
        }

        @include media-breakpoint-up(xl) {
          max-height: $brand-xl-max-height;
        }
      }
    }

    .main-navigation {
      @extend .navbar-collapse;

      @include media-breakpoint-down(md) {
        // Position the navigation after the logo (flex order = after the navigation)
        order: 3;

        ul > li {
          text-align: center;

          .dropdown-item {
            padding-top: map-get($spacers, 2);
            padding-bottom: map-get($spacers, 2);
          }

          & > a {
            padding: map-get($spacers, 3) 0;
            border-bottom: 1px solid theme-color(light);
          }
        }

        ul > li:last-of-type > a {
          border-bottom: none;
        }
      }

      @include media-breakpoint-up(lg) {
        // Position the navigation before the logo
        order: 2;
      }

      > ul {
        @extend .navbar-nav;
        @extend .mr-auto;

        > li {
          @extend .nav-item;

          &:first-child {
            > a {
              padding-left: 0 !important;
            }
          }

          > a {
            @extend .nav-link;
            color: theme-color('primary');
          }
        }
      }
    }


    // Fix alignment of header with content for md-breakpoint
    @include media-breakpoint-between(sm, md) {
      .container {
        padding: 0 $spacer;
      }
    }

    // shinked navigation bar (shinks when scrolling down)
    &.shrink {
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);

      // logo
      a.vorkurs-logo {
        img {
          @include media-breakpoint-down(md) {
            max-height: 40px;
          }

          @include media-breakpoint-only(lg) {
            max-height: 45px;
          }

          @include media-breakpoint-up(xl) {
            max-height: 45px;
          }
        }
      }
    }
  }
}

// =====================================================================================================================
// PAGE FOOTER
// =====================================================================================================================
footer {
  // margin-top: 40px;
  padding: 20px 0px;
  color: #ddd;

  // Redefine hyperlink style, since the default style is merely visible on the blue background
  a,
  a:hover,
  a:active,
  a:visited {
    color: #fff;
  }

  // Define a light text style to provide some merely visible "debug" information in the footer
  .light-text {
    color: desaturate(lighten(theme-color(), 20%), 50%);
  }
}
