.vorkurs-team {

  .col {
    @include make-col-ready();

    // Set default bottom margin (see overrides below)
    margin-bottom: map-get($spacers, 3);

    @include media-breakpoint-only(xs) {
      // Make column and setup number of columns per row (12/12 = 1 colums per row)
      @include make-col(12);

      // Remove bottom margin for elements in last row
      &:last-child {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-between(sm, md) {
      // Make column and setup number of columns per row (12/6 = 2 colums per row)
      @include make-col(6);

      // Remove bottom margin for elements in last row
      &:nth-last-child(1), &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      // Make column and setup number of columns per row (12/3 = 4 colums per row)
      @include make-col(3);

      // Remove bottom margin for elements in last row
      &:nth-last-child(1), &:nth-last-child(2), &:nth-last-child(3), &:nth-last-child(4) {
        margin-bottom: 0;
      }
    }
  }

  .team-member {
    @extend .tile;
    @extend .vorkurs-default-transition-duration;
    @extend .white-bg;


    text-align: center;
    height: 100% !important;
    margin-bottom: 0;

    .photo img {
      height: auto;
      max-width: 100%;
      max-height: 130px;
      border-radius: 50% !important;
      margin-bottom: map-get($spacers, 3);
    }

    .name {
      margin-bottom: map-get($spacers, 1);
    }

    .position {
      // Apply transition duration to position for the text color change (same transition as for tile)
      @extend .vorkurs-default-transition-duration;

      margin-bottom: 0;
      padding-bottom: 0;

      font-size: $small-font-size;
      color: $text-muted;
      text-align: center;
    }

    &:hover {
      .position {
        color: color-yiq($text-muted) !important;
      }
    }
  }
}
