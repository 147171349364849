@import "bootstrap/scss/variables";

.announcement {
  .announcement-icon {
    font-size: 48px;
  }

  .announcement-message {
    text-align: justify;

    h2 {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      color: #fff;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}