.subpage-overview {
  @include make-row();

  .subpage-item {
    @include make-col-ready();

    margin-bottom: map-get($spacers, 3);

    @include media-breakpoint-up(md) {
      @include make-col(4);
    }

    a {
      @extend .btn;
      @extend .btn-block;
      @extend .btn-primary;


      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;
      min-height: 6rem;

      h2 {
        font-size: $font-size-lg;
        margin-bottom: 0;
      }
    }
  }
}