// =====================================================================================================================
// Font Awesome 5
// =====================================================================================================================
$fa-font-path: "fonts/fontawesome";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/regular";
// =====================================================================================================================

// =====================================================================================================================
// Roboto font
// =====================================================================================================================
@import "_roboto";
// =====================================================================================================================


// =====================================================================================================================
// Bootstrap 4
// =====================================================================================================================
// Bootstrap defaults override
@import "_variables";

// Bootstrap
@import "bootstrap/scss/bootstrap";
// =====================================================================================================================

@import "vorkurs-page";
@import "vorkurs-header-and-footer";
@import "vorkurs-layout";
@import "vorkurs-tiles";
@import "vorkurs-announcement";
@import "vorkurs-news";
@import "vorkurs-team";
@import "vorkurs-feature-box";
@import "vorkurs-icons";
@import "vorkurs-collapsible";
@import "vorkurs-subpage-overview";

// Make all images of the content specified via Markdown automatically responsive
// (let them shrink if the screen size is too small)
.content img {
  @extend .img-fluid;
}

// ---------------------------------------------------------------------------------------------------------------------
// Page layout
// ---------------------------------------------------------------------------------------------------------------------

.page-layout {
  padding-top: 0;

  .page-layout-title {
    margin-bottom: $spacer;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// Landing page
// ---------------------------------------------------------------------------------------------------------------------

.vorkurs-card-title {
  margin-bottom: 0;

  // Position background image
  background-position: center;

  // Format the heading, the time, and the location
  color: #fff;
  font-weight: bold;

  // Shadow / outline around title, date, and location
  text-shadow: 1px 1px 3px black, -1px -1px 3px black, 0 0 15px black, 0 0 15px black;

  text-align: center;

  h2 {
    // color: #fff;
    // font-weight: 600;
  }

  @include media-breakpoint-down(sm) {
    // Move text to approx. center
    padding-top: 35px;
    padding-bottom: 35px;
  }

  @include media-breakpoint-up(md) {
    // Move text to approx. center
    padding-top: 35px;
    padding-bottom: 35px;

    .event-date,
    .event-location {
      display: inline;
    }
    .event-location {
      margin-left: $spacer;
    }
  }
}

.vorkurs-cards {
  .card-container + .card-container {
    @include media-breakpoint-down(sm) {
      margin-top: $spacer;
    }
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// Schedule
// ---------------------------------------------------------------------------------------------------------------------

.schedule-content {
  padding: 20px;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 15px;
}

.read-more {
  text-align: right;
}

.center-in-col {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.icon-next-to-content {
  display: flex;
  align-items: center !important;

  &.icon-on-left-side {
    .icon {
      margin-right: map-get($spacers, 4);
    }
  }

  &.icon-on-right-side {
    .icon {
      margin-left: map-get($spacers, 4);
    }
  }

  .icon {
    &.center-icon {
      text-align: center !important;
    }
    
    &.left-icon {
      text-align: left !important;
      // margin-right: map-get($spacers, 4);
    }
  }

  .content {
    flex-grow: 100;
  }
}

.two-column {
  &.with-center-line {
    .right-column {
      @include media-breakpoint-up(md) {
        border-left: $border-width solid $border-color !important;
      }
    }
  }

  .left-column {
    @include media-breakpoint-up(md) {
      padding-right: map-get($spacers, 4);
    }
  }

  .right-column {
    @include media-breakpoint-up(md) {
      padding-left: map-get($spacers, 4);
    }
  }
}

a.block-link {
  text-decoration: none;
}

.center-icon {
  margin-right: map-get($spacers, 1);
  text-align: center !important;
}

.icon-32 {
  min-width: 32px;

  .fas {
    font-size: 32px;
  }
}

.icon-48 {
  min-width: 64px;

  .fas {
    font-size: 48px;
  }
}

.icon-64 {
  min-width: 64px;

  .fas {
    font-size: 64px;
  }
}

.german-language-warning {
  border-top: $border-width solid $border-color !important;
  margin-top: map-get($spacers, 3);
  padding-top: map-get($spacers, 3);

  .icon-container {
    min-width: 64px;

    .icon {
      font-size: 32px;
    }
  }

  b {
    color: theme-color(danger);
  }
}

h1.page-title {
  margin-top: 50px;
  margin-bottom: 45px;
  text-align: center;
}