$post-secondary-fg-color: theme-color-level(dark, -4);
$avatar-height: 30px;

// =====================================================================================================================
// Common styling for all news content
// =====================================================================================================================
.news {
  .news-post {
    .vorkurs-tag {
      @extend .badge;
      @include badge-variant(theme-color(secondary));
      font-weight: normal;
    }

    .title {
      margin-bottom: 0;
    }

    .date {
      font-size: $small-font-size;
      color: $text-muted;
    }
  }
}

// =====================================================================================================================
// Styling for compact news list (start page)
// =====================================================================================================================
.news-compact-list {
  @extend .news;

  .news-post {
    @extend .tile;
    @extend .white-bg;

    .vorkurs-tag {
      @include badge-variant(theme-color(light));
      float: right;
    }

    .title {
      margin-top: map-get($spacers, 1);
    }

    .content {
      color: $post-secondary-fg-color !important;
    }
  }
}

// =====================================================================================================================
// Styling for extended news list (news overview page)
// =====================================================================================================================
.news-extended-list {
  @extend .news;

  .news-post {
    @extend .tile;
    @extend .white-bg;

    .vorkurs-tag {
      float: right;
    }


    .content {
      margin-top: map-get($spacers, 3);
      @include clearfix;
      color: $post-secondary-fg-color !important;
    }


    .meta-data {
      @include clearfix;
      margin-top: map-get($spacers, 3);
      padding-top: map-get($spacers, 3);
      border-top: $border-width solid $border-color;

      .authors .author {
        padding-right: map-get($spacers, 1);

        .avatar {
          height: $avatar-height !important;
          width: $avatar-height !important;
          border-radius: 50% !important;
          margin-right: map-get($spacers, 1);
        }

        .name {
          vertical-align: middle !important;
          line-height: $avatar-height;
          font-size: $small-font-size;
          color: $post-secondary-fg-color !important;
        }
      }

      .date {
        float: right;
        vertical-align: middle !important;
        font-size: $small-font-size;
        line-height: $avatar-height;
        color: $post-secondary-fg-color !important;
      }
    }
  }
}

// =====================================================================================================================
// Styling for news post details (single news post page)
// =====================================================================================================================
.news-single-post {
  @extend .news;

  .news-post {
    .vorkurs-tag-container {
      @include clearfix;

      .vorkurs-tag {
        float: right;
      }
    }

    .content {
      padding-bottom: map-get($spacers, 3);

      p:last-child {
        margin-bottom: 0;
      }
    }

    .meta-data {
      @include clearfix;
      padding-top: map-get($spacers, 3);
      padding-bottom: map-get($spacers, 3);
      border-top: $border-width solid $border-color;

      .authors .author {
        padding-right: map-get($spacers, 1);
        text-decoration: none !important;

        .avatar {
          height: $avatar-height !important;
          width: $avatar-height !important;
          border-radius: 50% !important;
          margin-right: map-get($spacers, 1);
        }

        .name {
          vertical-align: middle !important;
          line-height: $avatar-height;
          font-size: $small-font-size;
          color: $post-secondary-fg-color !important;
        }
      }

      .date {
        float: right;
        vertical-align: middle !important;
        font-size: $small-font-size;
        line-height: $avatar-height;
        color: $post-secondary-fg-color !important;
      }
    }
  }
}
