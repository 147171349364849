// #####################################################################################################################
// Override for Bootstrap's variable defaults
// #####################################################################################################################

// =====================================================================================================================
// Theme colors
// =====================================================================================================================
$theme-colors: (
        "primary": rgb(0, 84, 159),
        "danger": #ff4136
);

// =====================================================================================================================
// Sizes (for '.h-*' and '.w-*' classes)
// =====================================================================================================================
$sizes: (
        10: 10%,
        20: 20%,
        25: 25%,
        50: 50%,
        60: 60%,
        75: 75%,
        100: 100%,
        60px: 60px,
        80px: 80px,
        100px: 100px
);

// =====================================================================================================================
// Headings
// =====================================================================================================================
// Font
$headings-font-family: "Roboto", sans-serif;

// Font weight
$headings-font-weight: 300;

// Font sizes
$h-font-size-base: 1rem;
$h1-font-size: $h-font-size-base * 2.50;
$h2-font-size: $h-font-size-base * 2.00;
$h3-font-size: $h-font-size-base * 1.75;
$h4-font-size: $h-font-size-base * 1.50;
$h5-font-size: $h-font-size-base * 1.25;
$h6-font-size: $h-font-size-base * 1.00;

// =====================================================================================================================