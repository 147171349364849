@font-face {
  font-family: 'vorkurs-icons';
  src: url('fonts/vorkurs-icons/vorkurs-icons.eot?qnnt8x');
  src: url('fonts/vorkurs-icons/vorkurs-icons.eot?qnnt8x#iefix') format('embedded-opentype'),
  url('fonts/vorkurs-icons/vorkurs-icons.ttf?qnnt8x') format('truetype'),
  url('fonts/vorkurs-icons/vorkurs-icons.woff?qnnt8x') format('woff'),
  url('fonts/vorkurs-icons/vorkurs-icons.svg?qnnt8x#vorkurs-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="vorkurs-icons-"], [class*=" vorkurs-icons-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'vorkurs-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vorkurs-icons-array:before {
  content: "\e903";
}

.vorkurs-icons-if-then-else:before {
  content: "\e900";
}

.vorkurs-icons-loop:before {
  content: "\e901";
}

.vorkurs-icons-variable:before {
  content: "\e902";
}