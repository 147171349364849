
.vorkurs-layout {
  @include media-breakpoint-down(sm) {
    // margin-top: 70px;
  }

  @include media-breakpoint-up(md) {
    // margin-top: 125px;
  }

  .title {
    min-height: 150px;
    //   margin-bottom: $spacer;

    // Position background image
    background-position: center;

    // Format the heading, the time, and the location
    color: #fff;
    font-weight: bold;

    // Shadow / outline around title, date, and location
    // text-shadow: 1px 1px 3px black, -1px -1px 3px black, 0 0 5px black;

    h1 {
      color: rgb(0, 84, 159);
      font-weight: bold;
    }

    .event-details {
      color: rgb(0, 84, 159);
      background-color: rgba(255, 255, 255, 0.8);
      padding: 5px;
    }

    @include media-breakpoint-down(sm) {
      // Move text to approx. center
      padding-top: 20px;
      padding-bottom: 20px;
    }

    @include media-breakpoint-up(md) {
      // Move text to approx. center
      padding-top: 35px;
      padding-bottom: 35px;

      .event-details {
        display: inline;
      }
      .event-location {
        margin-left: $spacer;
      }
    }
  }
}